<template>
  <div class="titleText">
    <h1>Sound Pillards</h1>
    <p>A simple creative coding example</p>    
  </div>
  <div class="buttons">
    <button v-if="!playFlag" @click="onPlay()" class="button">Play</button>
    <button v-else @click="onPause()" class="button">Pause</button>
  </div>
</template>

<script>
import SoundReactor from "../classes/SoundReactor";
export default {
  name: "LoadingScreen",
  data() {
    return {
      initFlag: false,
      playFlag: false,
    };
  },
  methods: {
    onPlay() {
      if (!this.initFlag) {
        this.initFlag = true;
        SoundReactor.init();
      }
      SoundReactor.play();
      this.playFlag = true;
    },
    onPause() {
      SoundReactor.pause();
      this.playFlag = false;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus">
.titleText {
  position: absolute;
  top: 0;
  left: 0;
  color: white;
  margin-left:16px;
  margin-top:24px;  
}

.buttons {
  position: absolute;
  bottom: 0;
  right: 0;
  margin-bottom:24px;
  margin-right: 16px;
}

.button {
    color: white;
    background: transparent;
    border-radius: 200px;
    display: flex;
    justify-content: center;
    align-items: center;    
    padding: 0.4em 0.6em;
    border: solid purple;
    transition: all 0.3s;

    &:hover {
      background: purple;
    }
  }

  @media (min-width: 700px) {
    .titleText {     
      margin-left:50px;
      margin-top:50px;  
    }
    .buttons {
      margin-bottom:50px;
      margin-right: 50px;
    }
  }

  @media (min-width: 1366px) {
    .titleText {     
      margin-left:100px;
      margin-top:100px;  
    }
    .buttons {
      margin-bottom:100px;
      margin-right: 100px;
    }
    h1{
      font-size:50px;
    }
  }



</style>
